export const ADD_NOTE = 'ADD_NOTE';
export const CLEAR_FEATURE = 'CLEAR_FEATURE';
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const CLOSE_LOADER = 'CLOSE_LOADER';
export const CLOSE_MENU = 'CLOSE_MENU';
export const CLOSE_QUERY_INFO = 'CLOSE_QUERY_INFO';
export const FAVORITE_QUERY = 'FAVORITE_QUERY';
export const GET_QUERY = 'GET_QUERY';
export const LOAD_QUERIES = 'LOAD_QUERIES';
export const OPEN_ALERT = 'OPEN_ALERT';
export const OPEN_LOADER = 'OPEN_LOADER';
export const OPEN_MENU = 'OPEN_MENU';
export const OPEN_QUERY_INFO = 'OPEN_QUERY_INFO';
export const REMOVE_NOTE = 'REMOVE_NOTE';
export const SET_EXTENT = 'SET_EXTENT';
export const SET_FEATURE = 'SET_FEATURE';
export const UNFAVORITE_QUERY = 'UNFAVORITE_QUERY';
