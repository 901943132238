exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".app___JdOA7{width:100%;height:100%}", ""]);

// exports
exports.locals = {
	"app": "app___JdOA7"
};