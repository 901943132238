exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".search___J-ZlQ{padding:16px}.queries___bwYEV{display:grid;padding:16px;grid-template-columns:1fr 1fr 1fr;grid-column-gap:16px;grid-row-gap:16px}.queries___bwYEV .query___2j_bk .queryContent___E-aiR h3{margin:8px 0}.queries___bwYEV .query___2j_bk:last-child{margin-bottom:16px}@media(max-width: 900px){.queries___bwYEV{grid-template-columns:1fr 1fr}}@media(max-width: 600px){.queries___bwYEV{grid-template-columns:1fr}}.link___1mDiH{text-decoration:none}.loader___2SGfu{margin:auto}", ""]);

// exports
exports.locals = {
	"search": "search___J-ZlQ",
	"queries": "queries___bwYEV",
	"query": "query___2j_bk",
	"queryContent": "queryContent___E-aiR",
	"link": "link___1mDiH",
	"loader": "loader___2SGfu"
};