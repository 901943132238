exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".button___QUJ_T{z-index:10}.title___20V0k{display:block;padding:24px}.pre___3RkHP{white-space:pre-wrap}@media(max-width: 600px){.title___20V0k{padding:8px}.table___3eA1Q tr td{padding:0px 16px 0px 8px}}", ""]);

// exports
exports.locals = {
	"button": "button___QUJ_T",
	"title": "title___20V0k",
	"pre": "pre___3RkHP",
	"table": "table___3eA1Q"
};