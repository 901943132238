exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".content___2Lae3{display:flex;justify-content:center;min-width:400px}", ""]);

// exports
exports.locals = {
	"content": "content___2Lae3"
};