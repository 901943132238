exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".loader___2aKLr{left:0;margin:auto;position:absolute;right:0;text-align:center;top:85px;z-index:999}.loader___2aKLr>.chip___2ZNOk{padding-left:8px}.loader___2aKLr>.chip___2ZNOk>div:first-child{background-color:rgba(0,0,0,0)}.loader___2aKLr>.chip___2ZNOk svg{color:#fff}", ""]);

// exports
exports.locals = {
	"loader": "loader___2aKLr",
	"chip": "chip___2ZNOk"
};