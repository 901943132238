exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".panel___22iGL>div{width:400px}@media(max-width: 900px){.panel___22iGL>div{width:350px}}@media(max-width: 600px){.panel___22iGL>div{width:100%;height:400px}}.content___xNyID{padding:16px}.content___xNyID h1{padding-left:24px}.top___Xi2RM{height:64px}.top___Xi2RM button{float:right;margin:8px}.value___2YxaM{word-break:break-all}@media(max-width: 900px){.content___xNyID h1{padding:8px}.table___2FUlx tr th,.table___2FUlx tr td{padding:0px 16px 0px 8px}}", ""]);

// exports
exports.locals = {
	"panel": "panel___22iGL",
	"content": "content___xNyID",
	"top": "top___Xi2RM",
	"value": "value___2YxaM",
	"table": "table___2FUlx"
};