exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".menu___2LB9v{width:250px}.menu___2LB9v a{text-decoration:none}", ""]);

// exports
exports.locals = {
	"menu": "menu___2LB9v"
};